/**
 * @param {string} input
 */
 function toGo2(input) {
    const vals = input.split('-')
    const d = new Date(Date.UTC(vals[0], vals[1]-1, vals[2]));

    return d.toJSON();
}

export default toGo2