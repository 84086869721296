function toMysql(input) {
    const d = strZero(input.getDate(), 2);
    const m = strZero(input.getMonth()+1, 2);
    const y = input.getFullYear();

    return `${y}-${m}-${d}`;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default toMysql