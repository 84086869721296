/**
 * @param {Date} input input date
 * @param {string} withName month name: false | true | 'full'
 * @param {string} sep separator for without month name: default: '/'
 * @returns {string} brazillian formatted date: 1 Jan 2022 | 1 Janeiro 2022 | 1/1/2022
 */
 function toBrazilDate(input, withName, sep) {
    // if (typeof input !== 'string') {
    //     return '';
    // }

    const d = input;

    if (withName) {
        const ns = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const fns = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        let month = '';

        switch (withName) {
            case 'full':
                month = fns[d.getUTCMonth()];
                break;

            default:
                month = ns[d.getUTCMonth()];
        }

        return `${d.getUTCDate()} ${month} ${d.getUTCFullYear()}`;
    }

    if (!sep) {
        sep = '/';
    }

    return `${padLeft(d.getUTCDate(), 2)}${sep}${padLeft(d.getUTCMonth()+1, 2)}${sep}${d.getUTCFullYear()}`;
}

function padLeft(nr, n, str){
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}

export default toBrazilDate