/**
 * @param {string} input format: yyyy-mm-dd in brazil locale. input 2000-01-01: 2000-01-01 00:00:00 -0300
 */
 function fromMysqlBrasil(input) {
    if (typeof input !== 'string') {
        return undefined;
    }

    const vals = input.split('-');
    let result = new Date(input);
    result.setUTCFullYear(vals[0]);
    result.setUTCMonth(vals[1]-1);
    result.setUTCDate(vals[2]);
    result.setUTCHours(+3);

    return result;
}

export default fromMysqlBrasil