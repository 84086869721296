/**
 * @param {string} input
 */
 function fromGo(input) {
    if (typeof input !== 'string') {
        return '';
    }

    const dates = input.split('T')[0].split('-');

    var out = new Date(Date.UTC(
        dates[0],
        dates[1]-1,
        dates[2],
    ));

    return out;
}

export default fromGo