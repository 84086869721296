/**
 * @param {{}} input
 * @returns {string} Telefones formatados para exibição
 **/
function addressReadable(input) {
    if (!input || typeof input !== 'object') {
        return '';
    }
    if (input.line1 === undefined || input.city === undefined || input.state === undefined) {
        return '';
    }

    let address = `${input.line1}`;
    if (input.number) {
        address += `, ${input.number}`;
    }
    address += ` - ${input.city} - ${input.state}`;

    return address;
}

export default addressReadable
