/**
 * @param {Date} input
 */
function toMysqlBrasil(input) {
    const d = strZero(input.getUTCDate(), 2);
    const m = strZero(input.getUTCMonth()+1, 2);
    const y = input.getUTCFullYear();
    const result = `${y}-${m}-${d}`;

    return result;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default toMysqlBrasil