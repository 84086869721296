function currencyInputOptions() {
    return {
        "locale": "pt-BR",
        "currency": "BRL",
        "currencyDisplay": "hidden",
        "precision": 2,
        "hideCurrencySymbolOnFocus": true,
        "hideGroupingSeparatorOnFocus": false,
        "hideNegligibleDecimalDigitsOnFocus": false,
        "autoDecimalDigits": true,
        "valueScaling": "precision",
        "autoSign": true,
        "useGrouping": true,
        "accountingSign": false
    };
}

export default currencyInputOptions