function fromString(input) {
    if (typeof input !== 'string') {
        return 0;
    }

    const num = input.replace(/[^0-9-]/g, '');

    if (num.length === 0) {
        return 0;
    }

    return parseInt(num);
}

export default fromString