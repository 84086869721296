function todayMysql() {
    const t = new Date();

    const d = strZero(t.getDate(), 2);
    const m = strZero(t.getMonth()+1, 2);
    const y = t.getFullYear();

    return `${y}-${m}-${d}`;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default todayMysql