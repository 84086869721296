/**
 * @param {string} input
 */
 function fromMysql(input) {
    if (typeof input !== 'string') {
        return undefined;
    }

    const vals = input.split('-');
    let result = new Date(input);
    result.setFullYear(vals[0]);
    result.setMonth(vals[1]-1);
    result.setDate(vals[2]);
    result.setHours(0);

    return result
}

export default fromMysql