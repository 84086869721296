/**
 * @param {[]} input
 * @returns {string} Telefones formatados para exibição
 **/
function phonesReadable(input) {
    if (!input || typeof input !== 'object') {
        return '';
    }

    const regex = /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/gm;
    let out = [];
    let m;

    for (let i = 0; i < input.length; i++) {
        /** @type {string} */
        let element = input[i];

        while ((m = regex.exec(element)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            element = `(${m[1]}) ${m[2]}-${m[3]}`;
        }
        out.push(element);
    }

    return out.join(", ");
}

export default phonesReadable
