/**
 * @param {Number|String} numero
 * @param {Number} zeros
 * @returns {string} String com "zeros" à esquerda do número informado
 **/
function strZero(input, zeros) {
    if (String(input).length > zeros) {
        return String(input);
    }

    return Array(zeros-String(input).length+1).join('0') + input;
}

export default strZero

