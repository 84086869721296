import fromGo from "./fromGo";

/**
 * @param {string} input
 */
 function mysqlFromGo(input) {
    if (typeof input !== 'string') {
        return '';
    }

    const d = fromGo(input)
    const dia = strZero(d.getUTCDate(), 2);
    const mes = strZero(d.getUTCMonth()+1, 2);
    const out = `${d.getUTCFullYear()}-${mes}-${dia}`;

    return out
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default mysqlFromGo