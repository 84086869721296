import app from '../app'
import numbers from '../numbers'
import date from '../date'
import datetime from '../datetime'
import godate from '../godate'
import godatetime from '../godatetime'
import moeda from '../moeda'
import customer from '../customer'
import baseurl from '../baseurl'

/**
 * referência: https://www.npmjs.com/package/vuejs-dialog
 */

export default {
    app,
    numbers,
    date,
    datetime,
    godate,
    godatetime,
    moeda,
    customer,
    baseurl,
}
