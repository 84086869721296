import toGo from './toGo'
import toGo2 from './toGo2'
import fromGo from './fromGo'
import fromMysql from './fromMysql'
import toMysql from './toMysql'
import mysqlFromGo from './mysqlFromGo'
import todayMysql from './todayMysql'
import toBrazilDate from './toBrazilDate'
import datePart from './datePart'
import toMysqlBrasil from './toMysqlBrasil'
import fromMysqlBrasil from './fromMysqlBrasil'
// referência: https://www.npmjs.com/package/vuejs-dialog

export default {
    toGo,
    toGo2,
    fromGo,
    fromMysql,
    toMysql,
    mysqlFromGo,
    todayMysql,
    toBrazilDate,
    datePart,
    toMysqlBrasil,
    fromMysqlBrasil,
}
