/**
 * 
 * @param {number} entrada 
 * @returns number
 */
function toStringBrl(entrada) {
    // if (!entrada) {
    //     console.log('entrada: '+ entrada);
    //     return '';
    // }
    var formatado = '';
    var strBase = entrada.toString();
    var pad = "000";

    if (strBase.length <= 2) {
        strBase = pad.substring(0, pad.length - strBase.length) + strBase
    }
    const baseLen = strBase.replace('-', '').length;
    const totalDots = Math.trunc((baseLen - 3) / 3);

    formatado = strBase.slice(0, strBase.length-2) + ',' + strBase.slice(strBase.length-2)
    for (let i = 0; i < totalDots; i++) {
        const index = (formatado.indexOf(',') - 3) - (i * 4);
        formatado = formatado.slice(0, index) + '.' + formatado.slice(index);
    }

    return formatado;
}

export default toStringBrl




// let formatado = '000000000000';
// const baseLen = formatado.length;
// const totalDots = Math.trunc((baseLen - 3) / 3);
// console.log(totalDots);

// formatado = formatado.slice(0, formatado.length-2) + ',' + formatado.slice(formatado.length-2)
// for (let i = 0; i < totalDots; i++) {
//     const index = (formatado.indexOf(',') - 3) - (i * 4);
//     formatado = formatado.slice(0, index) + '.' + formatado.slice(index);
// }
// console.log(formatado);
